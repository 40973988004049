<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" class="d-flex">
        <v-row>
          <v-col cols="12" md="12">
            <v-card height="100%">
              <v-card-title>
                <v-avatar color="EoleBlue" size="48" class="mr-2">
                  <i class="fa-solid fa-flag-checkered fa-1x white--text"></i>
                </v-avatar>
                <span class="text-h6"> Préambule</span>
              </v-card-title>
              <v-card-text>
                <p>
                  La société <strong>Anavel</strong> respecte la vie privée et protège les données à caractère personnel
                  des utilisateurs de son site internet ainsi que de ses patients en assurant la protection, la
                  confidentialité, la disponibilité et la sécurité des données personnelles que vous nous confiez.
                </p>
              </v-card-text>
              <v-card-text>
                <p>
                  Ainsi, afin que vous puissiez naviguer sur notre site en toute tranquillité, nous vous expliquons
                  comment nous collectons, traitons et utilisons vos données personnelles. Nous prenons l’ensemble des
                  mesures nécessaires afin de : 
                  <ul>
                    <li>Vous fournir une information claire et transparente sur la manière dont
                  vos données personnelles seront collectées et traitées. </li>
                    <li>Conserver vos données personnelles uniquement
                  le temps nécessaire aux fins du traitement ou du service déterminé. </li>
                  <li>Vous offrir à tout moment la
                  possibilité d’accéder et de modifier vos données personnelles que nous traitons directement via vos
                  espaces personnels sur nos différents sites. Pour atteindre ces objectifs, nous mettons en œuvre les
                  mesures techniques et organisationnelles appropriées pour nous assurer que les traitements sont
                  conformes au droit applicable en matière de protection des données personnelles.</li>
                  </ul>
                  
                
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="12">
            <v-card height="100%">
              <v-card-title>
                <v-avatar color="EoleBlue" size="48" class="mr-2">
                  <i class="fa-solid fa-right-left fa-1x white--text"></i>
                </v-avatar>
                <span class="text-h6">Collecte et traitement de vos données personnelles</span>
              </v-card-title>
              <v-card-text>
                <span class="font-weight-bold">A. A QUELLES OCCASIONS COLLECTONS-NOUS VOS DONNÉES PERSONNELLES ?</span>

                <p>Vos données personnelles peuvent être recueillies lorsque :</p>
                <ul>
                  <li>Vous effectuez une demande de rendez-vous</li>
                  <li>Vous vous inscrivez sur le site internet</li>
                  <li>Vous effectuez des démarches en ligne</li>
                  <li>Vous déclarez un sinistre</li>
                  <li>Vous visitez nos sites internet qui peuvent utiliser des cookies</li>
                </ul>
                <br />
                <span class="font-weight-bold"
                  >B. QUELLES SONT LES INFORMATIONS PERSONNELLES QUE NOUS COLLECTONS ?</span
                >

                <p>Nous collectons les informations que vous nous fournissez notamment :</p>
                <ul>
                  <li>lorsque vous remplissez le formulaire de demande de rendez-vous </li>
                </ul>
                <p>
                  Les informations dont la collecte est strictement nécessaire pour vous fournir le service attendu
                  concernent généralement vos nom, prénom, téléphone, adresse de courrier électronique.
                </p>
                <p>
                  Les données recueillies sur le site ne peuvent parvenir que de l’enregistrement volontaire de vos
                  données à caractère personnel. <strong> Elles ne sont cédées à aucune société commerciale ou autre
                  organisation</strong>.
                </p>
                <p>
                  Elles ne sont utilisées que dans les limites précisées lors de leur collecte, pour vous fournir le
                  service ou les informations demandées. Elles ne seront pas stockées au-delà de la durée légalement
                  admise et requise à cette fin.
                </p>

                <span class="font-weight-bold">C. COMMENT UTILISONS-NOUS VOS DONNÉES PERSONNELLES ?</span>

                <p>Les personnes destinataires de vos données personnelles sont :</p>
                <ul>
                  <li>
                    Les seules personnes habilitées au sein de la société Anavel et/ou de l’entité concernée lorsque cet
                    accès est nécessaire à l’exécution de la prestation ;
                  </li>
                  <li>
                    Les prestataires de services pour la réalisation des prestations que nous leur aurons confiées.
                  </li>
                </ul>
                <p>
                  Vos données sont stockées dans le respect de la législation française et de la réglementation
                  européenne.
                </p>
                <p>
                  Les durées de conservations des données respectent les recommandations de la CNIL et/ou les
                  obligations légales.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="d-flex">
        <v-row>
          <v-col cols="12" md="24">
            <v-card>
              <v-card-title>
                <v-avatar color="EoleBlue" size="48" class="mr-2">
                  <i class="fa-solid fa-gavel fa-1x white--text"></i>
                </v-avatar>
                <span class="text-h6">Droits des personnes</span></v-card-title
              >
              <v-card-text>
                <p>
                  Conformément aux articles 13 et 14 de la Règlementation Générale sur la protection des données et dans
                  les limites posées par la loi, vous disposez des droits suivants :
                </p>
                <ul>
                  <li><strong>Droit d’information</strong> : Vous êtes informé des finalités du traitement</li>
                  <li>
                    <strong>Droit d’accès</strong>
                    : Vous avez le droit d’obtenir la confirmation que vos données sont traitées ou non en faisant une
                    demande auprès du délégué à la protection de données.
                  </li>
                  <li>
                    <strong>Droit de rectification </strong>
                    : Vous avez le droit d’obtenir, dans les meilleurs délais, que les données inexactes soient
                    rectifiées, et que les données incomplètes soient complétées
                  </li>
                  <li>
                    <strong>Droit à l’effacement</strong>
                    : Vous avez le droit d’obtenir dans les meilleurs délais, l’effacement de vos données si aucun motif
                    légitime ne justifie leur conservation
                  </li>
                  <li>
                    <strong>Droit à la limitation du traitement </strong>
                    : Vous avez le droit d’obtenir la limitation du traitement lorsque vous y êtes opposé, lorsque vous
                    contestez l’exactitude des données, lorsque le traitement est illicite, ou lorsque vous en avez
                    besoin pour la constatation, l’exercice ou la défense de vos droits en justice
                  </li>
                  <li>
                    <strong>Droit à la portabilité</strong>
                    : lorsque le traitement est fondé sur le consentement ou sur un contrat, et effectué à l’aide de
                    procédés automatisés, vous avez le droit de recevoir les données dans un format structuré,
                    couramment utilisé, lisible par machine et interopérable, et de les transmettre à un autre
                    responsable du traitement sans que le responsable du traitement initial y fasse obstacle
                  </li>
                  <li>
                    <strong>Droit d’opposition</strong>
                    : Vous avez le droit de vous opposer à tout moment au traitement des données, lorsque celui-ci est
                    nécessaire à l’exécution d’une mission d’intérêt public ou aux fins des intérêts légitimes du
                    responsable du traitement. Vous pouvez également vous opposer au traitement fait à des fins de
                    prospection
                  </li>
                  <li>
                    <strong>Prise de décision automatisée</strong>
                    : Vous avez le droit de ne pas faire l’objet d’une décision fondée exclusivement sur un traitement
                    automatisé, y compris le profilage, produisant des effets juridiques la concernant ou l’affectant,
                    sauf lorsque cette décision est nécessaire à la conclusion ou à l’exécution d’un contrat, est
                    autorisée légalement, ou est fondée sur son consentement.
                  </li>
                </ul>
                <p>Pour exercer vos droits, vous pouvez vous adresser à : david.audren@anavel.bzh.</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="12" class="d-flex">
            <v-card>
              <v-card-title>
                <v-avatar color="EoleBlue" size="48" class="mr-2">
                  <i class="fa-solid fa-shield-halved fa-1x white--text"></i>
                </v-avatar>
                <span class="text-h6">Sécurité de vos données</span></v-card-title
              >
              <v-card-text>
                Respecter votre droit à la protection, à la sécurité et à la confidentialité de vos données, est notre
                priorité. La société Anavel prend toutes les mesures raisonnablement possibles afin de protéger vos
                données à caractère personnel, recueillies lorsque vous utilisez ce site, contre la perte, l’utilisation
                malveillante et l’accès non autorisé, la divulgation, la modification ou la destruction. Nous sommes
                totalement engagés pour une protection efficace des données personnelles que vous nous confiez. Dans ce
                souci permanent de sécurité et de protection, nous vous encourageons à faire preuve de prudence pour
                empêcher tout accès non autorisé à vos données personnelles et à protéger vos terminaux (ordinateur,
                smartphone, tablette) contre tout accès non souhaité, voire malveillant, par un mot de passe robuste,
                qu’il est recommandé de changer régulièrement. Si vous partagez un terminal, nous vous recommandons de
                vous déconnecter après chaque utilisation. Veuillez prendre note qu’aucune connexion Internet n’est
                jamais sûre à 100%, ni exempte d’erreurs. En outre, il est de votre responsabilité de les protéger les
                mots de passe, numéros d’identification ou d’autres moyens d’accès sur ce site.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      articles: [
        {
          id: 1,
          title: "Éditeur du site",
        },
      ],
      firstname: "",
      lastname: "",
      formField: [
        {
          identity: {
            firstname: "",
            lastname: "",
          },
          address: {},
        },
      ],
    }
  },

  setup() {
    return {}
  },
}
</script>
